/* eslint-disable react/jsx-pascal-case */
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import * as React from 'react'
import { graphql } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

const AboutPage = ({ data }) => {
  console.log(data)
  const node = data.allMdx.nodes[0];
  const image = getImage(node.frontmatter.hero_image)
  const metaImage = node.frontmatter.hero_image.childImageSharp.resize
  return (
    <Layout section="me">
      <SEO
        title="me"
        description={node.excerpt}
        image={metaImage}
        pathname="/me"
      />
      <Col xl={8}>
        <Row>
          <GatsbyImage image={image} className="img-fluid rounded" />
          <h1 className='pt-2'>{node.frontmatter.title}</h1>
        </Row>
        <Row>
          <MDXRenderer>
            {node.body}
          </MDXRenderer>
        </Row>
      </Col>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMdx(filter: {frontmatter: {title: {eq: "me"}}}) {
      nodes {
        frontmatter {
          title
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
              resize(width: 1200) {
                src
                height
                width
              }
            }
          }
        }
        body
        excerpt(pruneLength: 160)
      }
    }
  }
`

export default AboutPage